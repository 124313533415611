<template>
  <div>
    <d-body-top-bar :title="$t('views.client.details.body.left.credits-pack-affect.add-credits-pack')">
      <div slot="tree">
        <label class="inactive-tree">
          {{ $t('views.subscriptions.subscriptions-list') }} /
          <label class="inactive-tree link-area pointer"
                 @click="$router.push({ name: 'customer_details', params: { id: $route.params.id} })">
            {{ name ? name : $t('views.client.details.body.left.subscription-affect.profile') }} /
          </label>
        </label>
        <label class="bg-doinsport-primary ml-1">
          {{ $t('views.client.details.body.left.credits-pack-affect.add-credits-pack') }}</label>
      </div>
      <div slot="icons">
      </div>
    </d-body-top-bar>
    <div class="container-fluid">
      <b-card
        :class="innerWidth ? '' : 'p-3'"
      >
        <validation-observer
          ref="creditsPackAffectObserver"
          slim
        >
          <b-row>
            <b-col
              :class="widthCheck (1100) ? 'col-divider-5': ''"
              :cols="widthCheck (1100) ? '' : 12"
            >
              <credits-pack-selection
                class="mt-3"
                :reload="reload"
                :selected-credits-pack="selectedCreditsPack"
              />
            </b-col>
            <div v-if="widthCheck (1100)" class="split-layout__divider">
              <div class="split-layout__rule"></div>
              <div class="split-layout__rule"></div>
            </div>
            <b-col :cols="widthCheck (1100) ? 6 : 12">
              <credits-pack-resume
                :customer="customer"
                :selected-credits-pack="selectedCreditsPack"
                class="mt-3"
              />
            </b-col>
          </b-row>
        </validation-observer>
        <b-row>
          <b-col align="right">
            <d-button
              text="general.actions.previous"
              class="d-btn-sm font-text-title btn d-btn-secondary font-text-title"
              @on:button-click="goToClient"
            />
            <d-button
              text="general.actions.validate"
              class="d-btn-sm font-text-title btn d-btn-danger font-text-title"
              :class="[selectedCreditsPack.selected ? '' : 'disabled', innerWidth ? '' : ' custom-customer-button mt-4 mb-3']"
              @on:button-click="onValidate"
            />
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>
<script>
import {getClubClient} from "@api/doinsport/services/client/client.api";
import Client from "@/classes/doinsport/Client";
import {postCustomerPaymentToken} from "@api/doinsport/services/client/payment-token/payment-token.api";
import {SUCCESS} from "@plugins/flash";

export default {
  data: () => ({
    reload: false,
    customer: null,
    selectedCreditsPack: {values: null, selected: false},
  }),
  components: {
    CreditsPackSelection: () => import('./CreditsPackSelection'),
    CreditsPackResume: () => import('./CreditsPackResume')
  },
  computed: {
    innerWidth(){
      return this.$store.getters['layout/getInnerWidth'] > 900;
    },
    name() {
      if (this.customer) {
        return this.customer.fullName;
      }
      return null;
    },
  },
  methods: {
    onValidate() {
      if (this.selectedCreditsPack.selected) {
        const items = this.selectedCreditsPack.values.items;

        for (const i in items) {
          const paymentToken = {
            client: "/clubs/clients/" + this.$route.params.id,
            paymentToken: items[i].paymentToken['@id'],
            operations: [
              {
                label: this.$t('views.client.details.body.left.credits-pack-affect.affected-credit'),
                credit: items[i].quantity,
              }
            ]
          }
          this.reload = true;
          postCustomerPaymentToken(paymentToken).then((response) => {
            if (parseInt(i) === items.length - 1) {
              this.$flash(null, this.$t('general.actions.success-update'), 3000, SUCCESS);
              this.selectedCreditsPack = {selected: false, values: null};
              // this.$router.replace({name: 'customer_details', params: {id: this.$route.params.id}})
            }
            this.reload = false;
          })
        }
      }
    },
    goToClient() {
      this.$router.replace({name: 'customer_details', params: {id: this.$route.params.id}});
    },
    widthCheck(check) {
      return this.$store.getters['layout/getInnerWidth'] > check;
    },
    loadCustomer() {
      getClubClient(this.$route.params.id)
        .then((response) => {
          this.customer = new Client(response.data, {serialize: true});
        })
      ;
    }
  },
  created() {
    this.loadCustomer();
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/_custom-buttom-mobile.scss";
@import "@lazy/_b-card.scss";
</style>
